<template>
    <div>
        <img src="../../../../assets/pc/images/about/problem.png">
    </div>
</template>

<script>
export default {
  name: 'problem',
  data () {
    return {

    };
  }
};
</script>
